import * as cornerstone from "cornerstone-core";
import * as cornerstoneFileImageLoader from "cornerstone-file-image-loader";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import { import as csTools } from "cornerstone-tools";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import * as dicomParser from "dicom-parser";
import Hammer from "hammerjs";
import React from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { activeDcm, activeMeasurements, clearStore, dcmIsOpen, dcmTool, doFsRefresh } from "../../../../store/actions";
import { uids } from "../constants/uids";
import db from "../db/db";
import { getSettingsOverlay, isFileImage, isFsFileImage, isUrlImage } from "../functions";
import CinePlayer from "./CinePlayer";
import OpenUrlDlg from "./OpenUrlDlg";
import ScrollDriven from "../../Scroll-Driven";

const scrollToIndex = csTools("util/scrollToIndex");
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneFileImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.init();

class DicomViewer extends React.Component {
  constructor(props) {
    super(props);
    this.localfile = null;
    this.localurl = "http://devicetest-viewer.vascularhealthclinics.org/";
    this.fsItem = null;
    this.dicomImage = null;
    this.imageId = null;
    this.image = null;
    this.isDicom = false;
    this.numberOfFrames = 1;
    this.measurements = [];
  }

  state = {
    file: null,
    visibleOpenUrlDlg: false,
    progress: null,
    visibleCinePlayer: false,
    errorOnOpenImage: null,
    errorOnCors: false,
    frame: 1,
    inPlay: false,
    viewport: null,
    urlImageLocal: "",
  };

  updateDicom = (value) => {
    const a = window.location.href;
    const b = (a && a.substring(a.indexOf("?") + 1)) || null;
    let url = "";

    if (b === "http://devicetest-viewer.vascularhealthclinics.org/") {
      return;
    } else {
      url = b;
    }

    this.loadImage(undefined, value || this.props.urlImage || b);
    this.props.runTool(this);
    this.props.changeTool(this);
    cornerstone.events.addEventListener("cornerstoneimageloaded", this.onImageLoaded);
    const { dcmRef } = this.props;
    dcmRef(this);
  };

  // componentDidMount() {
  //   // alert(window.location.href);
  //   this.updateDicom();
  // }

  componentWillUnmount() {
    this.props.runTool(undefined);
    this.props.changeTool(undefined);
    const { dcmRef } = this.props;
    dcmRef(undefined);
  }

  componentDidUpdate(previousProps) {
    const isOpen = this.props.isOpen[this.props.index];
    if (this.props.layout !== previousProps.layout && isOpen) {
      cornerstone.resize(this.dicomImage);
    }
    if (this.props.urlImage !== previousProps.urlImage) {
      this.loadImage(undefined, this.props.urlImage);
    }
  }

  onOpenUrl = (e) => {
    const eventData = e.detail;
    this.setState({ progress: eventData.percentComplete });
  };

  showOpenUrlDlg = (url) => {
    url = "http://devicetest-viewer.vascularhealthclinics.org/";
    this.loadImage(undefined, this.props.urlImage || url);
  };

  hideOpenUrlDlg = () => {
    this.setState({ visibleOpenUrlDlg: false, progress: null });
  };

  measurementSave = (measure) => {
    this.measurements.push(measure);
  };

  measurementClear = () => {
    this.measurements.splice(0, this.measurements.length);
  };

  measurementRemove = (index) => {
    this.measurements.splice(index, 1);
  };

  getTransferSyntax = () => {
    const value = this.image.data.string("x00020010");
    return value + " [" + uids[value] + "]";
  };

  getSopClass = () => {
    const value = this.image.data.string("x00080016");
    return value + " [" + uids[value] + "]";
  };

  getSopInstanceUID = () => {
    const value = this.image.data.string("x00080018");
    return value;
  };

  getPixelRepresentation = () => {
    const value = this.image.data.uint16("x00280103");
    if (value === undefined) return;
    return value + (value === 0 ? " (unsigned)" : " (signed)");
  };

  getPlanarConfiguration = () => {
    const value = this.image.data.uint16("x00280006");
    if (value === undefined) return;
    return value + (value === 0 ? " (pixel)" : " (plane)");
  };

  onImageLoaded = (e) => {
    console.log("cornerstoneimageloaded: ");
  };

  // Listen for changes to the viewport so we can update the text overlays in the corner
  onImageRendered = (e) => {
    const viewport = cornerstone.getViewport(e.target);

    if (
      this.isDicom &&
      document.getElementById(`mrtopleft-${this.props.index}`) &&
      document.getElementById(`mrtopright-${this.props.index}`) &&
      document.getElementById(`mrtopright-${this.props.index}`) &&
      document.getElementById(`mrbottomleft-${this.props.index}`) &&
      document.getElementById(`mrbottomright-${this.props.index}`)
    ) {
      document.getElementById(`mrtopleft-${this.props.index}`).textContent = `${this.PatientsName}`;

      document.getElementById(`mrtopright-${this.props.index}`).textContent = `${viewport.displayedArea?.brhc?.x}x${viewport.displayedArea?.brhc?.y}`;

      document.getElementById(`mrbottomleft-${this.props.index}`).textContent = `WW/WC: ${Math.round(viewport.voi.windowWidth)}/${Math.round(
        viewport.voi.windowCenter
      )}`;

      document.getElementById(`mrbottomright-${this.props.index}`).textContent = `Zoom: ${Math.round(viewport.scale.toFixed(2) * 100)}%`;
    }
    if (this.isDicom && this.state.visibleCinePlayer && this.numberOfFrames > 1) {
      document.getElementById(`frameLabel-${this.props.index}`).textContent = `${this.state.frame} / ${this.numberOfFrames}`;
      if (this.state.inPlay) {
        const frame = this.state.frame === this.numberOfFrames ? 1 : this.state.frame + 1;
        this.setState({ frame: frame });
      }
    }
  };

  onMeasurementModified = (e) => {
    // console.log('cornerstonetoolsmeasurementmodified: ', e.detail.measurementData)
  };

  onMeasurementAdded = (e) => {
    if (this.props.tool !== "Angle") return;
    const measure = {
      tool: this.props.tool,
      note: "",
      data: e.detail.measurementData,
    };
    this.measurementSave(measure);
    this.props.setActiveMeasurements(this.measurements);
  };

  onMeasurementCompleted = (e) => {
    // console.log('cornerstonetoolsmeasurementcompleted: ', e.detail.measurementData)
    const measure = {
      tool: this.props.tool,
      note: "",
      data: e.detail.measurementData,
    };
    this.measurementSave(measure);
    this.props.setActiveMeasurements(this.measurements);
  };

  onErrorOpenImageClose = () => {
    this.setState({ errorOnOpenImage: null });
  };

  onErrorCorsClose = () => {
    this.setState({ errorOnCors: false });
  };

  loadImage = (localfile, url = undefined, fsItem = undefined) => {
    if (localfile === undefined && url === undefined && fsItem === undefined) {
      return;
    }

    if (fsItem !== undefined) {
      this.fsItem = fsItem;
    } else if (localfile !== undefined) {
      this.localfile = localfile;
    } else {
      this.localurl = url;
    }

    const element = this.dicomImage;

    element.addEventListener("cornerstonenewimage", this.onNewImage);
    element.addEventListener("cornerstoneimagerendered", this.onImageRendered);
    element.addEventListener("cornerstonetoolsmeasurementadded", this.onMeasurementAdded);
    element.addEventListener("cornerstonetoolsmeasurementmodified", this.onMeasurementModified);
    element.addEventListener("cornerstonetoolsmeasurementcompleted", this.onMeasurementCompleted);

    let imageId = undefined;

    cornerstone.enable(element);
    if (localfile === undefined && isUrlImage(url)) {
      // check if it's a simple image [jpeg or png] from url
      // const file = "file:///C:/GARBAGE/_DCM/"+localfile?.name
      // console.log('image: ', file)
      cornerstone.loadImage(url).then(
        (image) => {
          this.hideOpenUrlDlg();

          this.image = image;
          this.isDicom = false;

          cornerstone.displayImage(element, image);

          this.enableTool();

          this.props.setActiveDcm({
            image: this.image,
            element: this.dicomImage,
            isDicom: this.isDicom,
          });
          this.props.isOpenStore(true);
        },
        (e) => {
          this.setState({
            errorOnOpenImage: "This is not a valid JPG or PNG file.",
          });
        }
      );
    } else if ((localfile !== undefined && isFileImage(localfile)) || (fsItem !== undefined && isFsFileImage(fsItem))) {
      // otherwise try to open as local image file (JPEG, PNG)
      if (fsItem !== undefined) {
        imageId = cornerstoneFileImageLoader.fileManager.addBuffer(fsItem.data);
      } else {
        imageId = cornerstoneFileImageLoader.fileManager.add(localfile);
      }
      cornerstone.loadImage(imageId).then(
        (image) => {
          this.image = image;

          this.isDicom = false;

          cornerstone.displayImage(element, image);

          this.enableTool();

          this.props.setActiveDcm({
            image: this.image,
            element: this.dicomImage,
            isDicom: this.isDicom,
          });
          this.props.isOpenStore(true);
        },
        (e) => {
          // console.log("error", e);
          this.setState({
            errorOnOpenImage: "This is not a valid JPG or PNG file.",
          });
        }
      );
    } else {
      // otherwise try to open as Dicom file

      if (fsItem !== undefined) {
        imageId = cornerstoneWADOImageLoader.wadouri.fileManager.addBuffer(fsItem.data);
      } else if (localfile !== undefined) {
        imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(localfile);
      } else {
        // it's a web dicom image
        imageId = "wadouri:" + url;
      }
      cornerstone.loadAndCacheImage(imageId).then(
        (image) => {
          this.hideOpenUrlDlg();

          this.image = image;

          this.isDicom = true;

          this.PatientsName = image.data.string("x00100010");
          this.sopInstanceUid = this.getSopInstanceUID();

          const stack = { currentImageIdIndex: 0, imageIds: "" };
          this.numberOfFrames = image.data.intString("x00280008");
          if (this.numberOfFrames > 0) {
            const imageIds = [];
            for (let i = 0; i < this.numberOfFrames; ++i) {
              imageIds.push(imageId + "?frame=" + i);
            }
            stack.imageIds = imageIds;
          }

          cornerstone.displayImage(element, image);

          this.enableTool();

          if (this.numberOfFrames > 1) {
            cornerstoneTools.addStackStateManager(element, ["stack", "playClip"]);
            cornerstoneTools.addToolState(element, "stack", stack);
            this.setState({ frame: 1 });
          }

          // Load the possible measurements from DB and save in the store
          db.measurement
            .where("sopinstanceuid")
            .equals(this.sopInstanceUid)
            .each((measure) => {
              this.measurementSave(measure);
              cornerstoneTools.addToolState(element, measure.tool, measure.data);
              this.runTool(measure.tool);
              cornerstone.updateImage(element);
              cornerstoneTools.setToolEnabled(measure.tool);
            })
            .then(() => {
              this.props.setActiveMeasurements(this.measurements);
              this.props.setActiveDcm({
                image: this.image,
                element: this.dicomImage,
                isDicom: this.isDicom,
              });
              this.props.isOpenStore(true);
            });
        },
        (e) => {
          this.hideOpenUrlDlg();
          if (e.error.toString() === "[object XMLHttpRequest]") {
            this.setState({ errorOnCors: true });
          } else {
            const pos = e.error.indexOf(":");
            this.setState({
              errorOnOpenImage: pos < 0 ? e.error : e.error.substring(pos + 1),
            });
          }
        }
      );
    }
  };

  enableTool = (toolName, mouseButtonNumber) => {
    // Enable all tools we want to use with this element
    const WwwcTool = cornerstoneTools.WwwcTool;
    const LengthTool = cornerstoneTools["LengthTool"];
    const PanTool = cornerstoneTools.PanTool;
    const ZoomTouchPinchTool = cornerstoneTools.ZoomTouchPinchTool;
    const ZoomTool = cornerstoneTools.ZoomTool;
    const ProbeTool = cornerstoneTools.ProbeTool;
    const EllipticalRoiTool = cornerstoneTools.EllipticalRoiTool;
    const RectangleRoiTool = cornerstoneTools.RectangleRoiTool;
    const FreehandRoiTool = cornerstoneTools.FreehandRoiTool;
    const AngleTool = cornerstoneTools.AngleTool;
    const MagnifyTool = cornerstoneTools.MagnifyTool;
    const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;

    cornerstoneTools.addTool(MagnifyTool);
    cornerstoneTools.addTool(AngleTool);
    cornerstoneTools.addTool(WwwcTool);
    cornerstoneTools.addTool(LengthTool);
    cornerstoneTools.addTool(PanTool);
    cornerstoneTools.addTool(ZoomTouchPinchTool);
    cornerstoneTools.addTool(ZoomTool);
    cornerstoneTools.addTool(ProbeTool);
    cornerstoneTools.addTool(EllipticalRoiTool);
    cornerstoneTools.addTool(RectangleRoiTool);
    cornerstoneTools.addTool(FreehandRoiTool);
    cornerstoneTools.addTool(StackScrollMouseWheelTool);
  };

  // helper function used by the tool button handlers to disable the active tool
  // before making a new tool active
  disableAllTools = () => {
    cornerstoneTools.setToolEnabled("Length");
    cornerstoneTools.setToolEnabled("Pan");
    cornerstoneTools.setToolEnabled("Magnify");
    cornerstoneTools.setToolEnabled("Angle");
    cornerstoneTools.setToolEnabled("RectangleRoi");
    cornerstoneTools.setToolEnabled("Wwwc");
    cornerstoneTools.setToolEnabled("ZoomTouchPinch");
    cornerstoneTools.setToolEnabled("Probe");
    cornerstoneTools.setToolEnabled("EllipticalRoi");
    cornerstoneTools.setToolEnabled("StackScrollMouseWheel");
  };

  runTool = (toolName, opt) => {
    switch (toolName) {
      case "openfile": {
        cornerstone.disable(this.dicomImage);
        this.setState({ file: opt });
        this.loadImage(opt);
        break;
      }
      case "openurl": {
        this.showOpenUrlDlg(opt);
        break;
      }
      case "openfs": {
        cornerstone.disable(this.dicomImage);
        this.setState({ file: opt });
        this.loadImage(undefined, undefined, opt);
        break;
      }
      case "clear": {
        this.setState({ visibleCinePlayer: false });
        this.props.clearingStore();
        cornerstone.disable(this.dicomImage);
        break;
      }
      case "notool": {
        this.disableAllTools();

        break;
      }
      case "Wwwc": {
        cornerstoneTools.setToolActive("Wwwc", { mouseButtonMask: 1 });
        break;
      }
      case "Pan": {
        cornerstoneTools.setToolActive("Pan", { mouseButtonMask: 1 });
        break;
      }
      case "Zoom": {
        cornerstoneTools.setToolActive(isMobile ? "ZoomTouchPinch" : "Zoom", {
          mouseButtonMask: 1,
        });
        break;
      }
      case "Length": {
        cornerstoneTools.setToolActive("Length", isMobile ? { isTouchActive: true } : { mouseButtonMask: 1 });
        break;
      }
      case "Probe": {
        cornerstoneTools.setToolActive("Probe", { mouseButtonMask: 1 });
        break;
      }
      case "EllipticalRoi": {
        cornerstoneTools.setToolActive("EllipticalRoi", { mouseButtonMask: 1 });
        break;
      }
      case "RectangleRoi": {
        cornerstoneTools.setToolActive("RectangleRoi", { mouseButtonMask: 1 });
        break;
      }
      case "Angle": {
        cornerstoneTools.setToolActive("Angle", { mouseButtonMask: 1 });
        break;
      }
      case "Magnify": {
        cornerstoneTools.setToolActive("Magnify", { mouseButtonMask: 1 });
        break;
      }
      case "FreehandRoi": {
        cornerstoneTools.setToolActive("FreehandRoi", { mouseButtonMask: 1 });
        break;
      }
      case "Invert": {
        const element = this.dicomImage;
        const viewport = cornerstone.getViewport(element);
        viewport.invert = !viewport.invert;
        cornerstone.setViewport(element, viewport);
        break;
      }
      case "cine": {
        this.setState({ visibleCinePlayer: !this.state.visibleCinePlayer });
        break;
      }
      case "reset": {
        this.reset();
        break;
      }
      case "removetool": {
        const element = this.dicomImage;
        cornerstoneTools.removeToolState(element, this.measurements[opt].tool, this.measurements[opt].data);
        cornerstone.updateImage(element);
        // this.props.measurementRemoveStore(opt)
        this.measurementRemove(opt);
        this.props.setActiveMeasurements(this.measurements);
        break;
      }
      case "removetools": {
        const element = this.dicomImage;
        // for each measurement remove it
        this.measurements.forEach((measure) => {
          cornerstoneTools.clearToolState(element, measure.tool);
        });
        cornerstone.updateImage(element);
        this.measurementClear();
        // also remove all measurements from db
        db.measurement.where("sopinstanceuid").equals(this.sopInstanceUid).delete();
        this.props.setActiveMeasurements(this.measurements);
        break;
      }
      case "savetools": {
        // first, remove eventually previous measurements from db
        db.measurement.where("sopinstanceuid").equals(this.sopInstanceUid).delete();
        // then save all the current measurements
        this.measurements.forEach((measure) => {
          try {
            db.measurement.add({
              sopinstanceuid: this.sopInstanceUid,
              tool: measure.tool,
              note: measure.note,
              data: measure.data,
            });
          } catch (error) {
            console.error(error);
          }
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  changeTool = (toolName, value) => {
    switch (toolName) {
      case "Wwwc":
        if (value === 1) {
          cornerstoneTools.setToolActive("Wwwc", { mouseButtonMask: 1 });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("Wwwc");
        }
        break;
      case "Pan":
        if (value === 1) {
          cornerstoneTools.setToolActive("Pan", { mouseButtonMask: 1 });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("Pan");
        }
        break;
      case "Zoom":
        if (value === 1) {
          cornerstoneTools.setToolActive(isMobile ? "ZoomTouchPinch" : "Zoom", {
            mouseButtonMask: 1,
          });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive(isMobile ? "ZoomTouchPinch" : "Zoom");
        }
        break;
      case "Length":
        if (value === 1) {
          cornerstoneTools.setToolActive("Length", isMobile ? { isTouchActive: true } : { mouseButtonMask: 1 });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("Length");
        }
        break;
      case "Probe":
        if (value === 1) {
          cornerstoneTools.setToolActive("Probe", { mouseButtonMask: 1 });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("Probe");
        }
        break;
      case "Angle":
        if (value === 1) {
          cornerstoneTools.setToolActive("Angle", { mouseButtonMask: 1 });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("Angle");
        }
        break;
      case "Magnify":
        if (value === 1) {
          cornerstoneTools.setToolActive("Magnify", { mouseButtonMask: 1 });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("Magnify");
        }
        break;
      case "EllipticalRoi":
        if (value === 1) {
          cornerstoneTools.setToolActive("EllipticalRoi", {
            mouseButtonMask: 1,
          });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("EllipticalRoi");
        }
        break;
      case "RectangleRoi":
        if (value === 1) {
          cornerstoneTools.setToolActive("RectangleRoi", {
            mouseButtonMask: 1,
          });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("RectangleRoi");
        }
        break;
      case "FreehandRoi":
        if (value === 1) {
          cornerstoneTools.setToolActive("FreehandRoi", { mouseButtonMask: 1 });
        } else if (value === 0) {
          cornerstoneTools.setToolPassive("FreehandRoi");
        }
        break;
      default:
        break;
    }
  };

  runCinePlayer = (cmdName) => {
    // console.log('this.state.frame: ', this.state.frame)
    const element = this.dicomImage;
    switch (cmdName) {
      case "firstframe": {
        const frame = 1;
        this.setState({ frame: frame });
        scrollToIndex(element, 0);
        break;
      }
      case "previousframe": {
        if (this.state.frame > 1) {
          const frame = this.state.frame - 1;
          this.setState({ frame: frame });
          scrollToIndex(element, frame - 1);
        }
        break;
      }
      case "play": {
        cornerstoneTools.playClip(element, 30);
        this.setState({ inPlay: true });
        break;
      }
      case "pause": {
        cornerstoneTools.stopClip(element);
        this.setState({ inPlay: false });
        break;
      }
      case "nextframe": {
        if (this.state.frame < this.numberOfFrames) {
          const frame = this.state.frame + 1;
          this.setState({ frame: frame });
          scrollToIndex(element, frame - 1);
        }
        break;
      }
      case "lastframe": {
        const frame = this.numberOfFrames;
        this.setState({ frame: frame });
        scrollToIndex(element, frame - 1);
        break;
      }
      default:
        break;
    }
  };

  reset = () => {
    const element = this.dicomImage;
    const defaultViewport = cornerstone.getDefaultViewportForImage(element, this.image);
    const viewport = cornerstone.getViewport(element);
    viewport.voi.windowWidth = defaultViewport.voi.windowWidth;
    viewport.voi.windowCenter = defaultViewport.voi.windowCenter;
    viewport.invert = false;
    cornerstone.setViewport(element, viewport);
  };

  dicomImageRef = (el) => {
    this.dicomImage = el;
  };

  onImageClick = () => {
    console.log("onImageClick: ");
  };

  render() {
    const isOpen = this.props.isOpen[this.props.index];
    const visibleOpenUrlDlg = this.state.visibleOpenUrlDlg;
    const progress = this.state.progress;

    const styleContainer = {
      width: "100%",
      height: "100%",
      border:
        this.props.setActiveDcmIndex === this.props.index && (this.props.layout[0] > 1 || this.props.layout[1] > 1)
          ? "solid 1px #AAAAAA"
          : "solid 1px #000000",
      position: "relative",
    };

    const styleDicomImage = {
      width: "100%",
      height: "100%",
      position: "relative",
    };

    const overlay = getSettingsOverlay();
    console.log(this.props.imageSub, "image subbbb");
    return (
      <div className="container" style={styleContainer}>
        {visibleOpenUrlDlg ? <OpenUrlDlg progress={progress} onClose={this.hideOpenUrlDlg} /> : null}
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            color: "#FFFFFF",
            textShadow: "1px 1px #000000",
          }}
          onContextMenu={() => false}
          className="cornerstone-enabled-image"
        >
          <ScrollDriven
            imageSub={this.props.imageSub}
            urlDefault={this.props.urlImage}
            updateDicom={this.updateDicom}
            styleDicomImage={styleDicomImage}
            dicomImageUpdate={(value) => {
              this.dicomImage = value;
            }}
            dicomImageRef={this.dicomImageRef}
          />
          <div
            id={`mrtopleft-${this.props.index}`}
            style={{
              position: "absolute",
              top: 0,
              left: 3,
              display: isOpen && overlay ? "" : "none",
            }}
          >
            Patient Name
          </div>
          <div
            id={`mrtopright-${this.props.index}`}
            style={{
              position: "absolute",
              top: 0,
              right: 3,
              display: isOpen && overlay ? "" : "none",
            }}
          >
            Size
          </div>
          <div
            id={`mrbottomright-${this.props.index}`}
            style={{
              position: "absolute",
              bottom: 0,
              right: 3,
              display: isOpen && overlay ? "" : "none",
            }}
          >
            Zoom:
          </div>
          <div
            id={`mrbottomleft-${this.props.index}`}
            style={{
              position: "absolute",
              bottom: 0,
              left: 3,
              display: isOpen && overlay ? "" : "none",
            }}
          >
            WW/WC:
          </div>
          {this.state.visibleCinePlayer && this.numberOfFrames > 1 ? (
            <div
              style={{
                position: "absolute",
                width: "100%",
                bottom: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  margin: "0 auto",
                  width: "240px",
                  backgroundColor: "rgba(136, 136, 136, 0.5)",
                }}
              >
                <CinePlayer runCinePlayer={this.runCinePlayer} inPlay={this.state.inPlay} />
                <div
                  id={`frameLabel-${this.props.index}`}
                  style={{
                    width: 230,
                    margin: "0 auto",
                    marginTop: -10,
                    textAlign: "center",
                  }}
                >
                  {this.state.frame} / {this.numberOfFrames}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.dicom.url,
    urlImage: state.dicom.urlImage,
    imageSub: state.dicom.imageSub,
    isOpen: state.dicom.isOpen,
    tool: state.dicom.tool,
    activeDcmIndex: state.dicom.activeDcmIndex,
    measurements: state.dicom.measurements,
    layout: state.dicom.layout,
    fsCurrentDir: state.dicom.fsCurrentDir,
    fsCurrentList: state.dicom.fsCurrentList,
    sandboxedFile: state.dicom.sandboxedFile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearingStore: () => dispatch(clearStore()),
    isOpenStore: (value) => dispatch(dcmIsOpen(value)),
    toolStore: (tool) => dispatch(dcmTool(tool)),
    setActiveDcm: (dcm) => dispatch(activeDcm(dcm)),
    setActiveMeasurements: (measurements) => dispatch(activeMeasurements(measurements)),
    makeFsRefresh: (dcm) => dispatch(doFsRefresh()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DicomViewer);
