import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";

export const clearStore = createActionThunk(AT.CLEAR_STORE, () => {});
export const localfileStore = createActionThunk(AT.LOCALFILE_STORE, (file) => file);
export const dcmIsOpen = createActionThunk(AT.DCM_IS_OPEN, (value) => value);
export const setURLImage = createActionThunk(AT.SET_URL_IMAGE, (value) => value);
export const setImageSub = createActionThunk(AT.SET_URL_IMAGE_SUB, (value) => value);
export const activeShowValue = createActionThunk(AT.ACTIVE_SHOW_VALUE, (value) => value);
export const dcmTool = createActionThunk(AT.DCM_TOOL, (tool) => tool);
export const activeDcmIndex = createActionThunk(AT.ACTIVE_DCM_INDEX, (index) => index);
export const activeDcm = createActionThunk(AT.ACTIVE_DCM, (dcm) => dcm);
export const dcmImage = createActionThunk(AT.DCM_IMAGE, (image) => image);
export const activeMeasurements = createActionThunk(AT.ACTIVE_MEASUREMENTS, (measurements) => measurements);
export const setLayout = createActionThunk(AT.LAYOUT, (row, col) => (row, col));
export const setDicomdir = createActionThunk(AT.DICOMDIR, (dicomdir) => dicomdir);
export const setFsCurrentDir = createActionThunk(AT.FSCURRENTDIR, (dir) => dir);
export const setFsCurrentList = createActionThunk(AT.FSCURRENTLIST, (list) => list);
export const setZippedFile = createActionThunk(AT.FSZIPPEDFILE, (file) => file);
export const doFsRefresh = createActionThunk(AT.FSREFRESH, () => {});
export const sandboxedfileStore = createActionThunk(AT.SANDBOXEDFILE_STORE, (file) => file);
