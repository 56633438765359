import React, { useState, useEffect, useRef, useCallback } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import _ from "lodash"; // Import lodash for throttle

const App = ({ dicomImageRef, styleDicomImage, imageSub, urlDefault, updateDicom }) => {
  const [currentFrame, setCurrentFrame] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const [visibleFrames, setVisibleFrames] = useState(10);
  const totalFrames = (imageSub && imageSub.length) || 50;
  const scrollContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const activeShow = useSelector((state) => state.dicom.activeShow);

  // Preload all images
  useEffect(() => {
    const preloadImages = () => {
      if (imageSub && imageSub.length > 0) {
        const images = imageSub.map((imageObj, index) => {
          const img = new Image();
          img.src = imageObj.image_path || urlDefault;
          return img;
        });
        setPreloadedImages(images);
      }
    };
    preloadImages();
  }, [imageSub, urlDefault]);

  const handleFrameChange = useCallback(
    (delta) => {
      setCurrentFrame((prevFrame) => {
        const newFrame = Math.min(Math.max(prevFrame + delta, 0), totalFrames - 1);
        return newFrame;
      });
    },
    [totalFrames]
  );

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollTop = scrollContainerRef.current.scrollTop;
      const maxScroll = scrollContainerRef.current.scrollHeight - scrollContainerRef.current.clientHeight;
      const scrollFraction = scrollTop / maxScroll;
      const frameIndex = Math.min(totalFrames - 1, Math.floor(scrollFraction * totalFrames));
      if (scrollTop + scrollContainerRef.current.clientHeight >= scrollContainerRef.current.clientHeight - 10) {
        // Load more frames if available
        setVisibleFrames((prevVisibleFrames) => Math.min(prevVisibleFrames + 5, totalFrames));
      }
      setCurrentFrame(frameIndex);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowDown") {
        handleFrameChange(-1); // Increment frame on Page Down
      } else if (e.key === "ArrowUp") {
        handleFrameChange(1); // Decrement frame on Page Up
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [totalFrames]);

  // Update canvas when the current frame changes
  useEffect(() => {
    if (activeShow) {
      updateDicom && updateDicom(imageSub[currentFrame].image_path);
    } else {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const img = preloadedImages[currentFrame];
      if (img) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
    }
  }, [currentFrame, activeShow, imageSub, preloadedImages]);

  if (activeShow) {
    return (
      <div
        ref={dicomImageRef}
        style={{
          ...styleDicomImage,
        }}
        className="size-dicom-reposive-image-chart"
      ></div>
    );
  }

  return (
    <div
      ref={dicomImageRef}
      style={{
        ...styleDicomImage,
        position: "relative",
      }}
      className="size-dicom-reposive-image-chart animation-container"
    >
      <div className="scrollable-content" ref={scrollContainerRef}>
        <div className="scroll-space" style={{ height: visibleFrames > 10 ? visibleFrames * 85 : 1000 }}></div>
      </div>
      <div className="fixed-image-container">
        <canvas ref={canvasRef} className="cornerstone-canvas" style={{ display: "block", width: "40vw", height: "63vh" }} />
      </div>
    </div>
  );
};

export default App;
