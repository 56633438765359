import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../common/components/Loading";
import BlockComponent from "../../../../common/components/TableDrafJSWord/BlockComponent";
import createTable from "../../../../common/components/TableDrafJSWord/createTable";
import CreateTextN from "../../../../helpers/CreateTextN";
import { getLocations, setGlobalLoading, setGlobalToastr } from "../../../../store/actions";
import valueFind from "./dataTableReview";
import dataHeader from "./formarterData";
import dataCheckVariance from "src/helpers/CheckVariance";
import dataCheckQuestion from "src/helpers/CheckQuestion";
import RenderFixStrongAndSpaceReview from "../../../../helpers/RenderFixStrongAndSpaceReview";
import DataVASCULOQOL from "../../../../data/VASCULOQOL";

const valueHeader = {
  headers: ["Qualifier", "Meets Criteria"],
};

const valueHeader2 = {
  headers: ["Qualifier", "Reference"],
};

let timer = null;
let timerNew = null;

const ReviewText = ({ changeValueText = () => {}, finalizedDate, finalizedDateDate, providerId, providerName }) => {
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);
  const vascuqol6 = useSelector((state) => state.patientExamination.vascuqol6);
  const hpi_conditions = useSelector((state) => state.clinicProviderReview.hpi_conditions);
  const additional_plan_text = useSelector((state) => state.clinicProviderReview.additional_plan_text);
  const additional_plan_text_lymphedema = useSelector((state) => state.clinicProviderReview.additional_plan_text_lymphedema);
  const conditions = useSelector((state) => state.clinicProviderHPI.conditions);
  const [HPICheck, setHPICheck] = useState("");
  const planSideBarData = useSelector((state) => state.common.planSideBarData);
  const medical_decision_making = useSelector((state) => state.clinicProviderReview.medical_decision_making);
  const blockRenderer = (block) => {
    /*if (block.getType() === "atomic" && valueHeader && valueFind && leftRight && hpi_conditions) {
      return {
        component: (props) => {
          return (
            <BlockComponent
              valueHeader2={valueHeader2}
              valueHeader={valueHeader}
              valueFind={valueFind}
              leftRight={leftRight}
              hpi_conditions={hpi_conditions}
              props={props}
            ></BlockComponent>
          );
        },
        editable: false,
      };
    }*/
    return null;
  };

  useEffect(() => {
    if (hpi_conditions && typeof hpi_conditions == "object" && Object.keys(hpi_conditions).length > 0) {
      const mapCheck = Object.keys(hpi_conditions).map((r, index) => {
        return hpi_conditions && hpi_conditions[r] && hpi_conditions[r][0]
          ? {
              note: hpi_conditions[r][0]["note"] || "",
            }
          : {};
      });
      setHPICheck([...mapCheck]);
    } else {
      setHPICheck([]);
    }
  }, [hpi_conditions]);

  const renderDataVariance = (dataFix, index, fillState) => {
    const textIN = dataFix?.[index]?.["text"];
    const indexFill = fillState.findIndex((fill) => fill?.type == textIN);
    // fillState is MAp full variance product have max 3
    // textIN is title variance on review
    // dataCheckVariance list template logic
    // find index title and add row variance
    if (indexFill != -1 && textIN) {
      if (fillState[indexFill]["extra"].length > 0) {
        return [
          ...dataFix.slice(0, index + 1),
          // go index will push data and push
          {
            key: `varisd${indexFill}empty`,
            text: "",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: `varisd${indexFill}`,
            text:
              dataCheckVariance?.[textIN]?.[fillState[indexFill]["value"]] +
                ` The patient contraindication is specific to ${fillState[indexFill]["extra"]}.` || "",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          // remove 2 row 1 row key title 1 row next properly is row empty
          ...dataFix.slice(index + 2),
        ];
      } else {
        return [
          ...dataFix.slice(0, index + 1),
          // go index will push data and push
          {
            key: `varisd${indexFill}emtpy`,
            text: "",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: `varisd${indexFill}`,
            text: dataCheckVariance?.[textIN]?.[fillState[indexFill]["value"]] || "",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          // remove 2 row 1 row key title 1 row next properly is row empty
          ...dataFix.slice(index + 2),
        ];
      }
    } else {
      return dataFix;
    }
  };

  const text_template_redux = useSelector((state) => state.clinicProviderReview.text_template);
  const ROSs = useSelector((state) => state.patientExamination.ROSs);
  const physical_examinations = useSelector((state) => state.patientExamination.physical_examinations || []);
  const vitals = useSelector((state) => state.patientExamination.vitals);
  const vessel_states = useSelector((state) => state.patientExamination.vessel_states);
  const icd_codes = useSelector((state) => state.clinicProviderReview.icd_codes);
  const medical_history = useSelector((state) => state.clinicProviderReview.medical_history);
  const previous_surgical_histories = useSelector((state) => state.clinicProviderReview.previous_surgical_histories);
  const medications = useSelector((state) => state.clinicProviderReview.medications);
  const allergies = useSelector((state) => state.clinicProviderReview.allergies);
  const family_history = useSelector((state) => state.clinicProviderReview.family_history);
  const chiefComplaint = useSelector((state) => state.clinicProviderReview.chiefComplaint);
  const discussion_text = useSelector((state) => state.clinicProviderReview.discussion_text);
  const social_history = useSelector((state) => state.clinicProviderReview.social_history);
  const mips = useSelector((state) => state.clinicProviderReview.mips);
  const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);
  const leftRight = useSelector((state) => state.clinicProviderReview.leftRight);
  const descList = useSelector((state) => state.clinicProviderReview.descList);
  const reason_for_visit = useSelector((state) => state.clinicProviderReview.reason_for_visit);
  const contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
  const editorState = EditorState.createWithContent(contentState);
  const [text, setText] = useState("");
  const [editorData, setEditorData] = useState(editorState);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const importCondition = (key, plans_row, temp_text, temp_length) => {
    switch (key) {
      case "Wound / Gangrene (CLI)": {
        // check Left
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} - The patient has signs and symptoms of CLI - Wound/Gangrene greater or equal to 2 weeks without improvement with local conservative wound care measures as defined by the ACC/AHA Executive Summary, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with CLI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for CLI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to minimize tissue loss.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        // check Right
        if (plans_row && plans_row.includes("Right Peripheral Angiogram (OBL)")) {
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} - The patient has signs and symptoms of CLI - Wound/Gangrene greater or equal to 2 weeks without improvement with local conservative wound care measures as defined by the ACC/AHA Executive Summary, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with CLI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for CLI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to minimize tissue loss.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Intermittent Claudication": {
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has failed conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention as well as lifestyle limiting symptoms affecting qOL.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for PAD necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to optimize arterial flow and improve symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        if (plans_row && plans_row.includes("Right Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has failed conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention as well as lifestyle limiting symptoms affecting qOL.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for PAD necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to optimize arterial flow and improve symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        if (plans_row && plans_row.includes("Lower Extremity Arteries")) {
          const text_value = `- Patient will undergo Guideline Directed Medical Therapy (GDMT) for a 12-week period and be re-evaluated for their intermittent claudication symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Acute Limb Ischemia (ALI)": {
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has signs and symptoms of ALI as defined by the ACC/AHA Executive Summary.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with ALI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for ALI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment for limb salvage.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        // check Right
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has signs and symptoms of ALI as defined by the ACC/AHA Executive Summary.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with ALI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for ALI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment for limb salvage.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Rest Pain (CLI)": {
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has signs and symptoms of CLI - Rest Pain as defined by the ACC/AHA Executive Summary, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with CLI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for CLI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to optimize arterial flow and improve symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        // check Right
        if (plans_row && plans_row.includes("Right Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has signs and symptoms of CLI - Rest Pain as defined by the ACC/AHA Executive Summary, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with CLI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for CLI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to optimize arterial flow and improve symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "In-Stent or Restenosis": {
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- Throughout the literature there are many studies that attempt to determine the best ways to manage a significant issue in endovascular care given the comorbidities of the patient.  There are NO guidelines or consensus.  Thus treatment of in-stent restenosis for which defined recommendations from the expert community do not exist.  At best the intervention of in-stent or restenosis is as the ACC/AHA best summarizes is a combination of clinical judgement and duplex ultrasound findings with evidence of hemodynamic significance which necessitates a diagnostic angiogram with possible angiogplasty versus stent placment to maintain primary assisted or secondary assisted patency.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        // check Right
        if (plans_row && plans_row.includes("Right Peripheral Angiogram (OBL)")) {
          const text_value = `- Throughout the literature there are many studies that attempt to determine the best ways to manage a significant issue in endovascular care given the comorbidities of the patient.  There are NO guidelines or consensus.  Thus treatment of in-stent restenosis for which defined recommendations from the expert community do not exist.  At best the intervention of in-stent or restenosis is as the ACC/AHA best summarizes is a combination of clinical judgement and duplex ultrasound findings with evidence of hemodynamic significance which necessitates a diagnostic angiogram with possible angiogplasty versus stent placment to maintain primary assisted or secondary assisted patency.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Abdominal Aortic Aneurysm (AAA) Disease": {
        if (plans_row && plans_row.includes("Aorta, IVC, Iliac Vasculature")) {
          const text_value = `- The defined ACC/AHA recommendations for a screening duplex ultrasound for Abdominal Aortic Aneurysm (AAA) is reasonable in patients with symptomatic peripheral arterial disease (PAD). (COR IIa)`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Lymphedema": {
        let text = "";
        (additional_plan_text_lymphedema || []).map((r, i) => {
          text += `{"key":"keyAdditionPlanTextlymp${i}","text":"${r}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        });
        if (plans_row) {
          return `{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},${text}`;
        } else {
          return `${text}`;
        }
      }
      case "Peripheral Artery Disease (PAD)": {
        let text = "";
        (additional_plan_text || []).map((r, i) => {
          text += `{"key":"keyAdditionPlanText${i}","text":"${r}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        });
        if (!plans_row) {
          return `${text}`;
        } else {
          return `{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},${text}`;
        }
      }
      // warning
      default:
        if (plans_row && temp_length) {
          // remove temp_text vì nó không nên add thêm cho general phía sau mà chỉ nhận từ api
          return `{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
    }
  };

  useEffect(() => {
    if (patientResource && typeof patientResource == "object" && Object.keys(patientResource).length > 0) {
      let data = JSON.stringify(convertToRaw(editorData.getCurrentContent()));
      if (!!is_locked == true && text_template_redux && text_template_redux.length > 6500) {
        data = text_template_redux;
        const contentState = convertFromRaw(JSON.parse(data));
        const editorState = EditorState.createWithContent(contentState);
        changeValueText(data);
        if (data && data.length > 6500) {
          setLoading(false);
          dispatch(setGlobalLoading(false));
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
          const checkRender = createTable(editorState, valueHeader, valueFind, valueHeader2);
          let tableAtomic = JSON.stringify(convertToRaw(checkRender.getCurrentContent()));
          tableAtomic = tableAtomic.replaceAll(`anally`, `anatomically`);
          const dataStrong = RenderFixStrongAndSpaceReview(tableAtomic);
          tableAtomic = JSON.stringify(dataStrong);
          tableAtomic = tableAtomic.replaceAll(`anally`, `anatomically`);
          changeValueText(tableAtomic);
          const contentStateWithTable = convertFromRaw(JSON.parse(tableAtomic));
          const editorStateWithTable = EditorState.createWithContent(contentStateWithTable);
          setText(editorStateWithTable);
        }, 1200);
      } else {
        setLoading(true);
        dispatch(setGlobalLoading(true));
        //Comment 106 -> 107 To save load Api review text
        //if (text_template_redux && text_template_redux.length > 6500) {
        //  data = text_template_redux
        //}
        // header 1
        const [head1, ...itemHead1] = data && data.split(`},{"key":"text1","text":"`);
        const checkHead1 = (itemHead1 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead1) {
          data = data.replace(
            `{"key":"text1","text":"${checkHead1[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"text1","text":"${
              patientResource?.first_name && patientResource?.last_name ? patientResource?.first_name + " " + patientResource?.last_name : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        // header 2
        const [head2, ...itemHead2] = data && data.split(`},{"key":"text2","text":"`);
        const checkHead2 = (itemHead2 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead2) {
          data = data.replace(
            `{"key":"text2","text":"${checkHead2[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"text2","text":"${
              patientResource?.id ? patientResource?.id : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"nonet2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        let plan_text = "";
        let patient_active_text = "";
        let hip_text = "";
        let data_review_text = "";
        let footer_text = "";
        let normal_active_problem_list = "";

        for (let key in descList || []) {
          if (descList.hasOwnProperty(key)) {
            let row = descList[key];
            for (let dec_row of row || []) {
              let left_finding = dec_row?.values[0].value ? CreateTextN(dec_row?.values[0].value) : null;
              let right_finding = dec_row?.values[1].value ? CreateTextN(dec_row?.values[1].value) : null;
              let left_conclusion = dec_row?.values[2].value ? CreateTextN(dec_row?.values[2].value) : null;
              let right_conclusion = dec_row?.values[3].value ? CreateTextN(dec_row?.values[3].value) : null;

              data_review_text += `${
                dec_row
                  ? `{"key":"keyemtdec${key}-${dec_row.title}-${
                      dec_row.date
                    }","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keydec${key}-${
                      dec_row.title
                    }-${dec_row.date}","text":"${dec_row.title} US (${
                      dec_row.date
                    })","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                      dec_row.title.length + 6 + dec_row.date.length
                    },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},
                {"key":"keyultrasound${key}-${dec_row.title}-${
                      dec_row.date
                    }space","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
                  {"key":"keyfindingtitle${key}-${dec_row.title}-${dec_row.date}-${dec_row.title}-${
                      dec_row.date
                    }","text":"FINDINGS:","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}},
                {"key":"keyfindingspace${key}-${dec_row.title}-${
                      dec_row.date
                    }","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
                ${left_finding ? `${left_finding},` : ""}
                {"key":"keyleftrightspace${key}-${dec_row.title}-${
                      dec_row.date
                    }","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
                 ${right_finding ? `${right_finding},` : ""}
                 {"key":"keyultrasound${key}-${dec_row.title}-${
                      dec_row.date
                    }space2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
                  {"key":"keyconclusiointitle${key}-${dec_row.title}-${dec_row.date}-${dec_row.title}-${
                      dec_row.date
                    }","text":"IMPRESSION:","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}},
                {"key":"keyconclusionspace${key}-${dec_row.title}-${
                      dec_row.date
                    }","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},
                ${left_conclusion ? `${left_conclusion},` : ""}
                  {"key":"keyconclusionleftrightspace${key}-${dec_row.title}-${
                      dec_row.date
                    }","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},
                  ${right_conclusion ? `${right_conclusion},` : ""}`
                  : ""
              }`;
            }
          }
        }
        let count_hpi = 0;
        for (let key in hpi_conditions || []) {
          // ngoại lệ cho Lymphedema
          const isPassLymphedema = key === "Lymphedema";
          const isPassPad = key === "Peripheral Artery Disease (PAD)";
          if (hpi_conditions.hasOwnProperty(key) || isPassLymphedema || isPassPad) {
            let row = hpi_conditions[key];
            for (let sub_row of row || []) {
              if ((sub_row["plans"] && sub_row["plans"].length > 0) || isPassLymphedema || isPassPad) {
                const textCheck =
                  hpi_conditions && hpi_conditions[key] && hpi_conditions[key][0] && hpi_conditions[key][0]["note"]
                    ? CreateTextN(hpi_conditions[key][0]["note"])
                    : null;
                let planRow = "";
                for (let plans_row of sub_row["plans"] ?? []) {
                  let temp_text = "";
                  if (plans_row.indexOf("Structured Exercise Program") > -1) {
                    temp_text = `- Planned program that provides individualized recommendations for type, frequency, intensity, and duration of exercise has been provided to the patient. The program provides recommendations for exercise progression to assure that the body is consistently challenged to increase exercise intensity and levels as functional status improves over time.  The patient has been counselled and provided with a defined plan for 30 minutes of walking 3-5 times a week with pedometer or other measures.  The structured exercise program will take place in the personal setting of the patient. The program is self-directed and is a regimen similar to that of a supervised program. The patient has been counselled to ensure that they understand how to begin the program, how to maintain the program, and how to progress the difficulty of the walking (by increasing distance or speed). Program incorporates behavioral change techniques, such as health coaching and/or use of activity monitors. (COR I)`;
                  } else if (plans_row.indexOf("Supervised Exercise Program") > -1) {
                    temp_text = `- A referral has been made for a planned program that takes place in a hospital or outpatient facility in which intermittent walking exercise is used as the treatment modality. Training is performed for a minimum of 30 to 45 min per session, in sessions performed at least 3 times/wk for a minimum of 12 wk. Training involves intermittent bouts of walking to moderate-to-maximum claudication, alternating with periods of rest. Warm-up and cool-down periods precede and follow each session of walking. (COR I)`;
                  } else if (plans_row.indexOf("Smoking Counseling") > -1 && key !== "General") {
                    temp_text = `- The patient was made aware of the risks of smoking including heart disease, peripheral artery disease, increased risk of cancers and lung disease. The patient has been counselled to quit smoking to ensure preventing worsening of vascular disease. The patient has been counselled on the numerous ways to quit smoking including cold turkey, medications, nicotine patches and therapy. The patient verbalizes their understanding.`;
                  } else if (plans_row.indexOf("Underweight Counseling") > -1) {
                    temp_text = `- Patient referred to nutrition counseling for BMI below normal parameters`;
                  } else if (plans_row.indexOf("Balance, Strength and Gait Training") > -1) {
                    temp_text = `- Balance, strength and gait training and instructions were provided`;
                  } else if (plans_row.indexOf("Aspirin") > -1) {
                    temp_text = `- Antiplatelet therapy with aspirin alone (range 75–325 mg per day) is recommended to reduce MI, stroke, and vascular death in patients with symptomatic PAD. (COR I)`;
                  } else if (plans_row.indexOf("Plavix") > -1) {
                    temp_text = `- Antiplatelet therapy with clopidogrel alone (75 mg per day) is recommended to reduce MI, stroke, and vascular death in patients with symptomatic PAD. (COR I)`;
                  } else if (plans_row.indexOf("Cilostazol") > -1) {
                    temp_text = `- Cilostazol is an effective therapy to improve symptoms and increase walking distance in patients with claudication. (COR I)`;
                  } else if (plans_row.indexOf("Chantix") > -1) {
                    temp_text = `- Patients with PAD who smoke cigarettes require a plan for quitting that includes pharmacotherapy (ie, varenicline, bupropion, and/or nicotine replacement therapy) (COR I)`;
                  } else if (plans_row.indexOf("Wellbutrin") > -1) {
                    temp_text = `- Patients with PAD who smoke cigarettes require a plan for quitting that includes pharmacotherapy (ie, varenicline, bupropion, and/or nicotine replacement therapy) (COR I)`;
                  } else if (plans_row.indexOf("Atorvastatin") > -1) {
                    temp_text = `- Treatment with a statin medication is indicated for all patients with PAD and vascular disease. (COR I)`;
                  } else {
                    temp_text = "";
                  }
                  let temp_length = 0;
                  if (plans_row.indexOf("Structured Exercise Program") > -1) {
                    temp_length = "Structured Exercise Program".length + 1;
                  } else if (plans_row.indexOf("Supervised Exercise Program") > -1) {
                    temp_length = "Supervised Exercise Program".length + 1;
                  } else if (plans_row.indexOf("Underweight Counseling") > -1) {
                    temp_length = "Underweight Counseling".length + 1;
                  } else if (plans_row.indexOf("Balance, Strength and Gait Training") > -1) {
                    temp_length = "Balance, Strength and Gait Training".length + 1;
                  } else if (plans_row.indexOf("Smoking Counseling") > -1) {
                    temp_length = "Smoking Counseling".length + 1;
                  } else if (plans_row.indexOf("Overweight Counseling") > -1) {
                    temp_length = "Overweight Counseling".length + 1;
                  } else if (plans_row.indexOf("Alcohol Counseling") > -1) {
                    temp_length = "Alcohol Counseling".length + 1;
                  } else if (plans_row.indexOf("Hypertension Counseling") > -1) {
                    temp_length = "Hypertension Counseling".length + 1;
                  } else if (plans_row.indexOf("Pain Management") > -1) {
                    temp_length = "Pain Management".length + 1;
                  } else {
                    if (plans_row && typeof plans_row == "string") {
                      temp_length = plans_row.length + 1;
                    }
                  }
                  // check index key to add condition
                  planRow += importCondition(key, plans_row, temp_text, temp_length) || "";
                }
                // ngoại lệ cho Lymphedema
                if (isPassLymphedema || isPassPad) {
                  planRow += importCondition(key, null, null, 0) || "";
                }
                //Remove empty line after the UL list
                plan_text += `${
                  count_hpi > 0
                    ? `{"key":"keyPlemptystart${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                    : ""
                }{"key":"keyid${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                  key.length + 1
                },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},
                {"key":"keyPlempty${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${
                  planRow ? `${planRow}` : ""
                }${
                  textCheck
                    ? `{"key":"keyTextCheckEmpty${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${textCheck},`
                    : ""
                }`;
              } else {
                const textCheck =
                  hpi_conditions && hpi_conditions[key] && hpi_conditions[key][0] && hpi_conditions[key][0]["note"]
                    ? CreateTextN(hpi_conditions[key][0]["note"])
                    : null;
                plan_text += `${
                  count_hpi > 0
                    ? `{"key":"keyPlemptystart${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                    : ""
                }{"key":"keyid${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                  key.length + 1
                },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},
                {"key":"keyPlempty${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${
                  textCheck ? `${textCheck},` : ""
                }{"key":"keyplanone${key}","text":"None","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              }

              if (key != "Prescription") {
                patient_active_text += `{"key":"keyptac${key}","text":"${key}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              }
              if (key != "Prescription" && key != "General") {
                normal_active_problem_list += key + ", ";
              }
              // text_template
              if (sub_row["text_template"].includes("blocks")) {
                let valueImport = sub_row["text_template"].replace(`{"blocks":[`, "");
                if (valueImport.includes(`"entityMap":[{`)) {
                  const [enti, ...entis] = valueImport && valueImport.split(`],"entityMap":[{"`);
                  const checkEnti = (entis || []).map((item) => {
                    return item.split(`]}`)[0];
                  });
                  if (checkEnti && checkEnti[0].length > 0) {
                    valueImport = valueImport.replace(`],"entityMap":[{"${checkEnti[0]}]}`, ``);
                  }
                  if (valueImport.includes(`],"entityMap":[]}`)) {
                    valueImport = valueImport.replace(`],"entityMap":[]}`, "");
                  }
                } else {
                  if (valueImport.includes(`],"entityMap":{}}`)) {
                    valueImport = valueImport.replace(`],"entityMap":{}}`, "");
                  }
                  if (valueImport.includes(`],"entityMap":[]}`)) {
                    valueImport = valueImport.replace(`],"entityMap":[]}`, "");
                  }
                }
                if (valueImport && valueImport.length > 0) {
                  let dataImport =
                    valueImport.slice(0, 1) == "{" &&
                    (valueImport.slice(valueImport.length - 1) == "}" || valueImport.slice(valueImport.length - 1) == ",")
                      ? JSON.parse(`[${valueImport}]`)
                      : null;
                  if (dataImport && Array.isArray(dataImport)) {
                    const arrSpecial = dataImport.filter((r) => r?.text && (r?.text.includes("•") || r?.text.includes("!!new!!")));
                    // fix !!new!! and fix !!ul!!
                    if (arrSpecial && arrSpecial.length > 0) {
                      arrSpecial.map((r) => {
                        const indexSpecial = dataImport.findIndex((ri) => ri?.key && ri?.key === r.key);
                        if (indexSpecial !== -1) {
                          const valueParse = JSON.parse(`[${CreateTextN(`${r.text}`)}]`) || null;
                          if (valueParse && Array.isArray(valueParse)) {
                            dataImport.splice(indexSpecial, 1, ...valueParse);
                          }
                        }
                      });
                    }
                    // fix ul li for value import text_template change to UI
                    let valueString = JSON.stringify(dataImport) || "";
                    if (dataImport && Array.isArray(dataImport)) {
                      const [headLi, ...itemHeadLi] = valueString && valueString.split(`!!UL!!`);
                      const checkHeadLi = (itemHeadLi || []).map((item) => {
                        return item.split(`",`)[0];
                      });
                      if (checkHeadLi) {
                        checkHeadLi.map((r) => {
                          if (r) {
                            valueString = valueString.replace(`${r}","type":"unstyled",`, `${r}","type": "unordered-list-item",`);
                          }
                        });

                        valueString = valueString.replaceAll("!!UL!!", "");
                      }
                      // remove []
                      const valueAll = valueString.substring(1, valueString.length - 1);
                      if (key) {
                        // import
                        var textNote = null;
                        if (hpi_conditions && hpi_conditions[key] && hpi_conditions[key][0] && hpi_conditions[key][0]["text"]) {
                          textNote = CreateTextN(`${hpi_conditions[key][0]["text"]}\n`);
                        }
                        hip_text += `{"key":"textspace${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
                      {"key":"keythip${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                          key.length + 1
                        },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textspacehiptext${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${
                          textNote ? `${textNote},` : ""
                        }${valueAll},`;
                        hip_text = hip_text.replace(`],"entityMap":[]}`, "");
                      }
                    }
                  }
                }
              }
              if (sub_row["footer_template"].includes("blocks")) {
                // footer_template
                let valueImportFooter = sub_row["footer_template"].replace(`{"blocks":[`, "");
                if (valueImportFooter.includes(`"entityMap":[{`)) {
                  const [enti, ...entis] = valueImportFooter && valueImportFooter.split(`],"entityMap":[{"`);
                  const checkEnti = (entis || []).map((item) => {
                    return item.split(`]}`)[0];
                  });
                  if (checkEnti && checkEnti[0] && checkEnti[0].length > 0) {
                    valueImportFooter = valueImportFooter.replace(`],"entityMap":[{"${checkEnti[0]}]}`, ``);
                  }
                  if (valueImportFooter.includes(`],"entityMap":[]}`)) {
                    valueImportFooter = valueImportFooter.replace(`],"entityMap":[]}`, "");
                  }
                } else {
                  valueImportFooter = valueImportFooter.replace(`],"entityMap":{}}`, ``);
                }
                if (valueImportFooter && valueImportFooter.length > 0) {
                  if (count_hpi !== 0) {
                    if (!footer_text.includes(`{"key":"textspaceend${key}"`) && !data.includes(`{"key":"textspaceend${key}"`)) {
                      footer_text += `${
                        count_hpi > 2
                          ? `{"key":"textemptdiscw${count_hpi}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                          : ""
                      }{"key":"keydisc${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                        (key && key.length + 1) || 0
                      },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textemptyfooter${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${valueImportFooter},`;
                    }
                  } else {
                    if (!footer_text.includes(`{"key":"keydisc${key}"`) && !data.includes(`{"key":"keydisc${key}"`)) {
                      footer_text += `${
                        count_hpi > 2
                          ? `{"key":"textemptdiscw${count_hpi}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                          : ""
                      }{"key":"keydisc${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                        (key && key.length + 1) || 0
                      },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textemptyfooter${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${valueImportFooter},`;
                    }
                  }

                  if (key === "Peripheral Artery Disease (PAD)") {
                  }
                  footer_text = footer_text.replace(`],"entityMap":[]}`, "");
                }
              } else {
                const mixText = CreateTextN(`${hpi_conditions[key][0]["text"]}`);
                if (mixText && mixText.length > 0) {
                  hip_text += `{"key":"textspace${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
                  {"key":"keythip${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                    key.length + 1
                  },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textspacehiptext${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${
                    mixText ? `${mixText},` : ""
                  }`;
                  hip_text = hip_text.replace(`],"entityMap":[]}`, "");
                }
              }
            }
            count_hpi++;
          }
        }
        if (hpi_conditions && hpi_conditions["Peripheral Artery Disease (PAD)"]) {
          footer_text += `{"key":"textspaceendbeforequestionnaire","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"questionnaireheader","text":"BCBS Peripheral Angiography Questionnaire","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":41,"style":"BOLD"},{"offset":0,"length":41,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`;
          footer_text += `{"key":"textkeyspacequestionnaireheader","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          (discussion_text || []).map((r, index) => {
            if (index == discussion_text.length - 1) {
              footer_text += `{"key":"discusion${index}","text":"${r}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            } else {
              footer_text += `{"key":"discusion${index}","text":"${r}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"discusionEmpty${index}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            }
          });
        }
        let ros_text = "";
        for (let row of ROSs || []) {
          const textCheck = row?.comments ? CreateTextN(row?.comments || "") : null;
          let checkMix = textCheck;
          if (checkMix && checkMix.includes(`"text":"`) && checkMix.includes(`"inlineStyleRanges":[]`)) {
            checkMix = checkMix.replace(`"text":"`, `"text":" ${row.type}: `);
            checkMix = checkMix.replace(
              `"inlineStyleRanges":[]`,
              `"inlineStyleRanges":[{"offset":0,"length":${(row.type && row.type.length + 1) || 0},
        "style":"BOLD"}]`
            );
            if (checkMix) {
              ros_text += `${checkMix ? `${checkMix},` : ""}`;
            }
          }
        }
        if (hip_text && hip_text.length > 0) {
          let patient_name =
            patientResource?.first_name && patientResource?.last_name ? patientResource?.first_name + " " + patientResource?.last_name : "";
          normal_active_problem_list = normal_active_problem_list.substring(0, normal_active_problem_list.length - 2);
          let _reason_for_visit = "";
          if (reason_for_visit && reason_for_visit.length > 0) {
            _reason_for_visit = reason_for_visit.toLowerCase();
          }
          // header 3
          const [head3, ...itemHead3] =
            data &&
            data.split(
              `{"key":"6qn3","text":"HISTORY OF PRESENTING ILLNESS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":29,"style":"BOLD"},{"offset":0,"length":29,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
            );
          const checkHead3 = (itemHead3 || []).map((item) => {
            return item.split(`{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
          });
          if (checkHead3 && checkHead3[0]) {
            data = data.replace(
              `${checkHead3[0]},{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"textemptyintrotext","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"introtext","text":"I had the pleasure of seeing ${patient_name} in vascular surgery clinic today. As you know the patient is a ${patientResource?.age} year old ${patientResource?.gender} who presents as a ${_reason_for_visit} who has a previous medical history of ${patientResource?.previous_medical_history} and is being evaluated for ${normal_active_problem_list}.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${hip_text}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          } else {
            if (!data.includes(hip_text)) {
              data = data.replace(
                `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                `{"key":"textemptyintrotext","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"introtext","text":"I had the pleasure of seeing ${patient_name} in vascular surgery clinic today. As you know the patient is a ${patientResource?.age} year old ${patientResource?.gender} who presents as a ${_reason_for_visit} who has a previous medical history of ${patientResource?.previous_medical_history} and is being evaluated for ${normal_active_problem_list}.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${hip_text}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              );
            }
          }
        }
        if (ros_text && ros_text.length > 0) {
          if (!data.includes(ros_text)) {
            data = data.replace(
              `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"textempty4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${ros_text}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        let vitals_text = "";
        for (let row of vitals || []) {
          let tempalteCode = "";
          if (row.type == "Temperature") {
            tempalteCode = "℉";
          } else if (row.type == "BP (Right)" || row.type == "BP (Left)") {
            tempalteCode = "mmHg";
          } else if (row.type == "HR") {
            tempalteCode = "BPM";
          } else if (row.type == "Resp") {
            tempalteCode = "RR";
          } else if (row.type == "SpO2") {
            tempalteCode = "%";
          } else if (row.type == "Weight") {
            tempalteCode = "Kg";
          } else if (row.type == "Height") {
            tempalteCode = "Inches";
          } else if (row.type == "Pain Rating") {
            tempalteCode = "/10";
          } else {
            tempalteCode = "";
          }
          vitals_text += `{"key":"keyvit${row.type}","text":"${row.type}: ${
            row?.value + " " + tempalteCode
          }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
            (row.type && row.type.length + 1) || 0
          },"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        if (vitals_text && vitals_text.length > 0) {
          if (!data.includes(vitals_text)) {
            data = data.replace(
              `{"key":"text5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"textempty5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${vitals_text}{"key":"text5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        let vessel_states_text_right = "";
        let vessel_states_text_left = "";
        let physical_examinations_text = "";
        let vessel_states_check = [];
        if (Array.isArray(vessel_states)) {
          vessel_states_check = vessel_states;
        } else {
          if (vessel_states && Object.keys(vessel_states).length > 0) {
            vessel_states_check = Object.keys(vessel_states).map((r) => {
              return {
                type: r,
                value: vessel_states[r] ?? "",
              };
            });
          }
        }
        for (let row of vessel_states_check || []) {
          if (row.type.includes("Right ") && row.type && row.type.length > 0) {
            vessel_states_text_right += `${
              row?.value && row.type
                ? ` ${row.type.replace("Right ", "").toUpperCase()}: ${row?.value == "X" ? "NOT APPLICABLE" : row?.value}${
                    row?.value == "2" ? "+" : ""
                  },`
                : ""
            }`;
          } else if (row.type.includes("Left ") && row.type && row.type.length > 0) {
            vessel_states_text_left += `${
              row?.value && row.type
                ? ` ${row.type.replace("Left ", "").toUpperCase()}: ${row?.value == "X" ? "NOT APPLICABLE" : row?.value}${
                    row?.value == "2" ? "+" : ""
                  },`
                : ""
            }`;
          }
        }

        for (let row of physical_examinations || []) {
          const textCheck = row?.comments ? CreateTextN(row?.comments || "") : null;
          let checkMix = textCheck;
          if (
            checkMix &&
            checkMix.includes(`"text":"`) &&
            checkMix.includes(`"inlineStyleRanges":[]`) &&
            vessel_states_text_right &&
            vessel_states_text_right.length > 0
          ) {
            checkMix = checkMix.replace(`"text":"`, `"text":"${row.type}: `);
            checkMix = checkMix.replace(
              `"inlineStyleRanges":[]`,
              `"inlineStyleRanges":[{"offset":0,"length":${row.type && row.type.length + 1},
        "style":"BOLD"}]`
            );
            if (checkMix) {
              physical_examinations_text += `
          ${checkMix ? `${checkMix},` : ""}
          ${
            row.type == "Extremities"
              ? `{"key":"rplus","text":"Right Pulses: ${
                  vessel_states_text_right ? vessel_states_text_right.substr(0, vessel_states_text_right.length - 1) : ""
                }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},
            {"key":"rlpes","text":"Left Pulses: ${
              vessel_states_text_left ? vessel_states_text_left.substr(0, vessel_states_text_left.length - 1) : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}},`
              : ""
          }`;
            }
          }
        }

        if (physical_examinations_text && physical_examinations_text.length > 0) {
          // header 6
          if (!data.includes(physical_examinations_text)) {
            data = data.replace(
              `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"textempty6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${physical_examinations_text}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        let icd_codes_text = "";
        for (let row of icd_codes || []) {
          icd_codes_text += `${
            row.icd_name
              ? `{"key":"keyicd${row.icd_name}${row?.icd_code}","text":"${row.icd_name} (${row?.icd_code})","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              : ""
          }`;
        }
        if (icd_codes_text && icd_codes_text.length > 0) {
          // header 8
          if (!data.includes(icd_codes_text)) {
            data = data.replace(
              `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"textempty8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${icd_codes_text}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        if (plan_text && plan_text.length > 0) {
          // more Space
          //plan_text += `{"key":"textSpacePlanText","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          // header 9
          const [head9, ...itemHead9] =
            data &&
            data.split(
              `{"key":"fjqg5","text":"PLAN","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":4,"style":"BOLD"},{"offset":0,"length":4,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
            );
          const checkHead9 = (itemHead9 || []).map((item) => {
            return item.split(`{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
          });
          if (checkHead9 && checkHead9[0] && checkHead9[0].length > 0) {
            data = data.replace(`${checkHead9[0]},`, `${plan_text}`);
          } else {
            data = data.replace(
              `{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${plan_text}`
            );
          }
        }
        let medical_history_text = "";
        if (medical_history && medical_history.length > 0) {
          for (let row of medical_history || []) {
            medical_history_text += `{"key":"keymedi${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        } else {
          medical_history_text += `${`{"key":"keymedihistory","text":"No Medical History","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`}`;
        }

        if (footer_text && footer_text.length > 0) {
          // end plan
          if (hpi_conditions && hpi_conditions["Peripheral Artery Disease (PAD)"] && hpi_conditions["Peripheral Artery Disease (PAD)"][0]) {
            const textEndPlan = `The Class of Recommendation (COR) Guidelines are referenced within the 2016 AHA/ACC Guideline on the Management of Patients With Lower Extremity Peripheral Artery Disease: Executive Summary. A Report of the American College of Cardiology/American Heart Association Task Force on Clinical Practice Guidelines`;
            const textEndPlanBlock = `{"key":"textendPlanText","text":"${textEndPlan}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;

            data = data.replace(
              `{"key":"discu1s","text":"DISCUSSION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"},{"offset":0,"length":10,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`,
              `{"key":"text10startPlan","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${textEndPlanBlock}{"key":"text10endPlan","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"discu1s","text":"DISCUSSION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"},{"offset":0,"length":10,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
            );
          }

          //
          if (!data.includes(footer_text)) {
            data = data.replace(
              `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${footer_text}`
            );
          }
        }

        if (medical_history_text && medical_history_text.length > 0) {
          // header 11
          const [head11, ...itemHead11] =
            data &&
            data.split(
              `{"key":"0i92j","text":"PAST MEDICAL HISTORY","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":20,"style":"BOLD"},{"offset":0,"length":20,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
            );
          const checkHead11 = (itemHead11 || []).map((item) => {
            return item.split(`{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
          });
          if (checkHead11 && checkHead11[0]) {
            data = data.replace(
              `${checkHead11[0]},{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text11empzt","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${medical_history_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          } else {
            if (!data.includes(medical_history_text)) {
              data = data.replace(
                `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                `{"key":"text11empzt","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${medical_history_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              );
            }
          }
        }

        let previous_surgical_histories_text = "";
        if (previous_surgical_histories) {
          for (let row of previous_surgical_histories || []) {
            let name = row?.name && row?.name.length > 0 && row?.name.includes(`"`) ? row?.name.replace(/"/g, "") : row?.name;
            previous_surgical_histories_text += `${
              !row?.id
                ? `{"key":"keyprev12${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                : `{"key":"keyprev32${row?.id}${name}","text":"${name}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            }`;
          }
        } else {
          previous_surgical_histories_text = `{"key":"keyprev32nosurgicalhistory","text":"None","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }

        if (previous_surgical_histories_text && previous_surgical_histories_text.length > 0) {
          // header 12
          const [head12, ...itemHead12] =
            data &&
            data.split(
              `{"key":"3d92j","text":"PAST SURGICAL HISTORY","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":21,"style":"BOLD"},{"offset":0,"length":21,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
            );
          const checkHead12 = (itemHead12 || []).map((item) => {
            return item.split(`{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
          });
          if (checkHead12 && checkHead12[0] && checkHead12[0].length > 0) {
            data = data.replace(
              `${checkHead12[0]},{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text12emtpzy","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${previous_surgical_histories_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          } else {
            data = data.replace(
              `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text12emtpzy","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${previous_surgical_histories_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        let family_history_text = "";
        for (let row of family_history || []) {
          family_history_text += `${
            row
              ? `{"key":"keyprev67${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              : ""
          }`;
        }

        if (family_history_text && family_history_text.length > 0) {
          // header 15
          if (!data.includes(family_history_text)) {
            data = data.replace(
              `{"key":"text15","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text15emtyps","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${family_history_text}{"key":"text15","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        let social_history_text = "";
        for (let row of social_history || []) {
          social_history_text += `${
            row
              ? `{"key":"keyprev${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              : ""
          }`;
        }

        if (social_history_text && social_history_text.length > 0) {
          if (!data.includes(social_history_text)) {
            data = data.replace(
              `{"key":"text16","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text16emtpys","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${social_history_text}{"key":"text16","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        let medications_text = "";
        if (medications.length > 0) {
          for (let row of medications || []) {
            medications_text += `${
              row.full_medication && row?.id
                ? `{"key":"keymedi${row?.id}","text":"${row.full_medication}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                : ""
            }`;
          }
        } else {
          medications_text += `${`{"key":"keymedi1","text":"No Known Medications","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`}`;
        }

        if (medications_text && medications_text.length > 0) {
          // header 13
          if (!data.includes(medications_text)) {
            data = data.replace(
              `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text13emptyz","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${medications_text}{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        let allergies_text = "";
        if (allergies.length > 0) {
          for (let row of allergies || []) {
            allergies_text += `${
              row?.allergy_name
                ? `{"key":"keyall${row?.allergy_name}","text":"${row?.allergy_name}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                : ""
            }`;
          }
        }

        if (allergies_text && allergies_text.length > 0) {
          // header 14
          if (!data.includes(allergies_text)) {
            data = data.replace(
              `{"key":"text14","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text14emtyps","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${allergies_text}{"key":"text14","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        if (data_review_text && data_review_text.length > 0) {
          // header 17
          if (!data.includes(data_review_text)) {
            data = data.replace(
              `{"key":"text17","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${data_review_text}{"key":"text17","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        let mips_text = "";
        if (mips.length > 0) {
          for (let row of mips || []) {
            mips_text += `{"key":"keymip${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        }

        if (mips_text && mips_text.length > 0) {
          // header 19
          if (!data.includes(mips_text)) {
            data = data.replace(
              `{"key":"text25","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${mips_text}{"key":"text25","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        let provider_text = "";
        // PROVIDER
        if (providerName && providerName.length > 0) {
          provider_text = `{"key":"prvnmc","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          // header 18
          if (!data.includes(provider_text)) {
            data = data.replace(
              `{"key":"text18","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${provider_text}{"key":"text18","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        if (patient_active_text && patient_active_text.length > 0) {
          // header 21
          if (!data.includes(patient_active_text)) {
            data = data.replace(
              `{"key":"text21","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"text21spze","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${patient_active_text}{"key":"text21","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        //SIGNATURE
        const [headSig, ...itemHeadSig] =
          data && data.split(`{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
        const checkHeadSig = (itemHeadSig || []).map((item) => {
          return item.split(
            `{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        const textSigText = `I, ${
          providerName || ""
        } , hereby attest that the medical record entry for ${finalizedDateDate} accurately reflects signatures/notations that I made in my capacity as the provider when I treated/diagnosed the above listed beneficiary. I do hereby attest that this information is true, accurate and complete to the best of my knowledge and I understand that any falsification, omission, or concealment of material fact may subject me to administrative, civil, or criminal liability.`;

        let singalKey = `{"key":"txtporscio","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscioempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
        {"key":"textsigelectsing","text":"Electronically signed by ${providerName} on ${finalizedDate}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtsigempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textsigsText","text":"${textSigText}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;

        if (providerName) {
          if (
            providerName.indexOf("Amy") > -1 ||
            providerName.indexOf("Leah") > -1 ||
            providerName.indexOf("Jennifer") > -1 ||
            providerName.indexOf("Pragna") > -1 ||
            providerName.indexOf("Chris") > -1
          ) {
            singalKey += `{"key":"txtporscioempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscioempty3","text":"My collaborating physician is Omar Haqqani, MD.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        }
        if (checkHeadSig && checkHeadSig[0] && checkHeadSig[0].length > 0) {
          data = data.replace(
            `${checkHeadSig[0]},{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${singalKey}{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        } else {
          if (!data.includes(singalKey)) {
            data = data.replace(
              `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
          ${singalKey}`
            );
          }
        }

        //DAte FINALIZED
        let date_signed_text = "";
        date_signed_text = `{"key":"keydatesigned","text":"${finalizedDate}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`;

        const [headDate, ...itemHeadDate] = data && data.split(`{"key":"keydatesigned",`);
        const checkHeadDate = (itemHeadDate || []).map((item) => {
          return item.split(`,"data":{}}`)[0];
        });
        if (checkHeadDate && checkHeadDate[0] && checkHeadDate[0].length > 0) {
          data = data.replace(`{"key":"keydatesigned",${checkHeadDate[0]},"data":{}}`, `${date_signed_text}`);
        } else {
          data = data.replace(
            `{"key":"text20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${date_signed_text},{"key":"text20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }

        // medical_decision_making CLASSIFICATION OF EVALUTION:
        if (medical_decision_making) {
          if (medical_decision_making == "High-Complexity") {
            let minutes = Math.floor(Math.random() * (54 - 40 + 1) + 40);
            data = data.replace(
              `{"key":"cofeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"cofeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe0","text":"Medical Decision Making is ${medical_decision_making}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"cofeempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe1","text":"The number and complexity of problem(s) that are addressed during the above encounter is 1 or more chronic illnesses with severe exacerbation, progression, or side effects of treatment; and/or 1 acute or chronic illness or injury that poses a threat to life or bodily function","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe2","text":"The amount and/or complexity of data reviewed and analyzed include review of prior external note(s) from each unique source; review of the result(s) of each unique test; ordering of each unique test; independent interpretation of a test performed by another physician; discussion of management","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe3","text":"The risk of complications and/or morbidity or mortality of patient management was discussed due to the high risk of morbidity from additional diagnostic testing or treatment","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"mescoffelegal","text":"Total time on the date of the encounter including both face-to-face and non-face-to-face time personally spent by the physician and/or other qualified health care professional(s) on the day of the encounter (includes time in activities that require the physician or other qualified health care professional is ${minutes} minutes.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"mescoffeempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          } else if (medical_decision_making == "Moderate-Complexity") {
            let minutes = Math.floor(Math.random() * (39 - 30 + 1) + 30);
            data = data.replace(
              `{"key":"cofeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"cofeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe0","text":"Medical Decision Making is ${medical_decision_making}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"cofeempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe1","text":"The number and complexity of problem(s) that are addressed during the above encounter is 1 or more chronic illnesses with exacerbation, progression, or side effects of treatment; and/or 2 or more stable, chronic illnesses","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe2","text":"The amount and/or complexity of data reviewed and analyzed include review of the result(s) of each unique test; ordering of each unique test; independent interpretation of a test performed by another physician; discussion of management","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe3","text":"The risk of complications and/or morbidity or mortality of patient management was discussed due to the moderate risk of morbidity from additional diagnostic testing or treatment","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"mescoffelegal","text":"Total time on the date of the encounter including both face-to-face and non-face-to-face time personally spent by the physician and/or other qualified health care professional(s) on the day of the encounter (includes time in activities that require the physician or other qualified health care professional is ${minutes} minutes.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"mescoffeempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          } else {
            let minutes = Math.floor(Math.random() * (29 - 20 + 1) + 20);
            data = data.replace(
              `{"key":"cofeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"cofeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe0","text":"Medical Decision Making is ${medical_decision_making}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"cofeempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe1","text":"The number and complexity of problem(s) that are addressed during the above encounter is at least 1 stable, chronic illness","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe2","text":"The amount and/or complexity of data reviewed and analyzed include review of the result(s) of each unique test; ordering of each unique test","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffe3","text":"The risk of complications and/or morbidity or mortality of patient management was discussed due to the low risk of morbidity from additional diagnostic testing or treatment","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              {"key":"mescoffeempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"mescoffelegal","text":"Total time on the date of the encounter including both face-to-face and non-face-to-face time personally spent by the physician and/or other qualified health care professional(s) on the day of the encounter (includes time in activities that require the physician or other qualified health care professional is ${minutes} minutes.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"mescoffeempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        // make more

        // remove text nếu ethnicity == white
        if (patientResource?.ethnicity === "White") {
          data = data.replace(
            `{"key":"amosduj2","text":"AMPLIFIERS OF CARDIOVASCULAR AND LIMB-RELATED RISK IN PATIENTS WITH PERIPHERAL ARTERY DISEASE","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":93,"style":"BOLD"},{"offset":0,"length":93,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textemptyamos1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textasmosduj1","text":"Clinicians require to actively pursue evidence of health disparities in diagnosis, treatment, and outcomes for patients with PAD and use efforts to limit the impact of these disparities on clinical outcomes. (COR I)","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textemptyamos2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textasmosduj2","text":"Non-White patients in the United States have high rates of risk-amplifying comorbidities of PAD and higher risk of adverse outcomes. In the United States, Non-White patients have a higher risk of limb amputation compared with White populations.  In addition, disparities in medical insurance and access to experienced health care centers providing advanced care for PAD and limb salvage procedures may contribute to the increased potential for lower extremity amputation. The disproportionately higher risk of amputation among patients of underrepresented racial and ethnic groups may also reflect systemic bias as studies have shown that Non-White patients with PAD are more likely to undergo amputation without attempts at revascularization and are more frequently treated with proximal amputation than White patients.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textemptyamos3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            ""
          );
        }
        //

        if (vascuqol6 && Array.isArray(vascuqol6) && vascuqol6.length > 0) {
          // make text VASCULOQOL
          let textVASCULOQOL = `{"key":"textemptyVasculoqolck2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          let valueTotal = 0;

          vascuqol6.map((r) => {
            // lấy index của VAS
            const indexVAS = DataVASCULOQOL.findIndex((find) => find?.key === r?.type);
            if (indexVAS != -1) {
              // lấy index value trong VAS
              const indexValueVAS = DataVASCULOQOL[indexVAS]["dataChoices"].findIndex((find) => find === r?.value);
              const valueVas = indexValueVAS + 1 > 0 ? indexValueVAS + 1 : 0;
              valueTotal = valueTotal + Number(valueVas);
              textVASCULOQOL += `{"key":"${r?.type}","text":"${DataVASCULOQOL[indexVAS]["question"]} [${r?.value}] [${valueVas}]","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            }
          });
          textVASCULOQOL += `{"key":"valueTotalVASEmpty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"valueTotalVAS","text":"Total Score: ${valueTotal}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":13,"length":20,"style":"BOLD"}],"entityRanges":[],"data":{}},`;

          const [headVAS, ...itemHeadVAS] =
            data &&
            data.split(`{"key":"textemptyVasculoqol2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
          const checkHeadVAS = (itemHeadVAS || []).map((item) => {
            return item.split(
              `{"key":"textemptyVasculoqol3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            )[0];
          });

          if (checkHeadVAS && checkHeadVAS[0] && checkHeadVAS[0].length > 0) {
            data = data.replace(`${checkHeadVAS[0]}`, `${textVASCULOQOL}`);
          } else {
            data = data.replace(
              `{"key":"textemptyVasculoqol2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${textVASCULOQOL}{"key":"textemptyVasculoqol3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        } else {
          // remove nếu ko có vas
          data = data.replace(
            `{"key":"vasculoqoltitle","text":"VASCULAR QUALITY OF LIFE QUESTIONNAIRE (VASCUQOL-6)","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":51,"style":"BOLD"},{"offset":0,"length":51,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textemptyVasculoqol1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textVasculoqol1","text":"The VascuQoL-6 aims to assess QoL in PAD regardless of disease severity (ie. in intermittent claudication and in critical limb ischemia).  The main goal of vascular surgical treatment efforts in peripheral arterial disease (PAD) is limb salvage in critical limb ischemia and improvement of walking ability in intermittent claudication. The treatment effort outcomes require to address the PAD patient’s own perception about his or her actual functional walking capacity, pain discomfort, or the social and emotional implications of living with PAD is a essential measures to provide clinically useful additional decision-basis and also important information about the results of different medical interventions.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textemptyVasculoqol2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textemptyVasculoqol3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            ""
          );
        }

        // make cilo
        const valueObject = JSON.parse(data);
        if (valueObject?.blocks) {
          let dataCheck = [...(valueObject?.blocks || [])];
          //  checkCondition Variance and make data to filter
          const IndexCondition = (conditions || []).findIndex((r) => r?.condition === "Peripheral Artery Disease (PAD)");
          if (IndexCondition !== -1) {
            let fillState = [];
            const valueQuest = conditions?.[IndexCondition]?.["questions"] || [];
            valueQuest.map((r) => {
              if (r?.variance) {
                fillState.push({
                  value: r?.variance,
                  extra: r?.variance_extra || "",
                  name: r?.question,
                  type: dataCheckQuestion[r?.question] || "",
                });
              }
            });
            // make replace form key variance -> next key variance, its will delete 2 row
            let dataFix = dataCheck;
            const indexCilo = dataFix.findIndex((inx) => inx?.text == "Cilostazol Therapy");

            if (indexCilo != -1) {
              dataFix = renderDataVariance(dataFix, indexCilo, fillState);
            }
            const indexAnti = dataFix.findIndex((inx) => inx?.text == "Antiplatelet Therapy");
            if (indexAnti != -1) {
              dataFix = renderDataVariance(dataFix, indexAnti, fillState);
            }
            const IndexStat = dataFix.findIndex((inx) => inx?.text == "Statin Therapy");
            if (IndexStat != -1) {
              dataFix = renderDataVariance(dataFix, IndexStat, fillState);
            }
            const IndexAce = dataFix.findIndex((inx) => inx?.text == "ACE Inhibitor or Angiotensin Receptor Blocker Therapy");
            if (IndexAce != -1) {
              dataFix = renderDataVariance(dataFix, IndexAce, fillState);
            }
            let allData = {
              blocks: dataFix,
              entityMap: valueObject?.entityMap || {},
            };
            data = JSON.stringify(allData);
          }
        }
        // end

        const contentState = convertFromRaw(JSON.parse(data));
        const editorState = EditorState.createWithContent(contentState);
        changeValueText(data);
        // create table
        clearTimeout(timerNew);
        timerNew = setTimeout(() => {
          const checkRender = createTable(editorState, valueHeader, valueFind, valueHeader2);
          let tableAtomic = JSON.stringify(convertToRaw(checkRender.getCurrentContent()));
          const dataStrong = RenderFixStrongAndSpaceReview(tableAtomic);
          tableAtomic = JSON.stringify(dataStrong);
          tableAtomic = tableAtomic.replaceAll(`anally`, `anatomically`);
          changeValueText(tableAtomic);
          const contentStateWithTable = convertFromRaw(JSON.parse(tableAtomic));
          const editorStateWithTable = EditorState.createWithContent(contentStateWithTable);
          setText(editorStateWithTable);
        }, 1200);
        setLoading(false);
        dispatch(setGlobalLoading(false));
      }
    }
  }, [
    patientResource,
    hpi_conditions,
    ROSs,
    physical_examinations,
    vitals,
    vessel_states,
    icd_codes,
    medical_history,
    chiefComplaint,
    discussion_text,
    text_template_redux,
    HPICheck,
    providerName,
    valueFind,
    is_locked,
    leftRight,
    finalizedDate,
    finalizedDateDate,
    planSideBarData,
    conditions,
    medical_decision_making,
    vascuqol6,
  ]);

  useEffect(() => {
    dispatch(getLocations());
    setEditorData(editorState);
  }, []);

  const onChange = async (editorState) => {
    if (!loading) {
      setText(editorState);
      let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      changeValueText(data);
    }
  };

  return (
    <div className="rm-toolbar">
      {loading ? (
        <Loading />
      ) : (
        <div style={{ width: "100%", height: "100%" }}>
          <Editor
            toolbar={{
              options: ["inline", "list"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            editorStyle={{
              border: "1px solid #f2f2f2",
              padding: "10px",
              height: "100%",
              backgroundColor: "#fff",
            }}
            placeholder="Patient Review"
            editorState={loading ? "" : text}
            onEditorStateChange={onChange}
            blockRendererFn={blockRenderer}
            readOnly={is_locked}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewText;
