import React, { Component } from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { activeShowValue, get_image_list, setGlobalLoading, setGlobalToastr, setImageSub, setURLImage } from "../../../store/actions";
import Dicom from "../../components/Dicom";
import { Tabs } from "@material-ui/core";
import DeleteIconButton from "src/common/components/icons/DeleteIconButton";
import { delete_patient_image } from "src/services/api.services";
import { getLocal } from "src/helpers/Local";

class VideoImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      Hidelink: false,
      Videofile: false,
      imageCheck: "",
      width: 800,
      height: 500,
      url: "",
      readyToReset: false,
      checkRender: true,
      idActive: -1,
      isPlay: true,
      indexActive: -1,
      itemActive: null,
    };
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.image_list && nextProp.image_list.length > 0 && nextProp?.id_image === 0) {
      this.setState({
        Hidelink: false,
        images: (nextProp.image_list && nextProp.image_list[0] && nextProp.image_list[0].images) || [],
      });
    } else if (nextProp.image_list && nextProp.image_list.length > 0 && nextProp?.id_image > 0) {
      this.setState({
        Hidelink: false,
        images: (nextProp.image_list && nextProp.image_list[`${nextProp?.id_image}`] && nextProp.image_list[`${nextProp?.id_image}`].images) || [],
      });
    } else if (!nextProp.image_list || nextProp.image_list.length === 0) {
      this.setState({
        Hidelink: true,
      });
    }
    if (nextProp?.resetClick) {
      this.setState({
        images: [],
        Hidelink: false,
        Videofile: false,
        imageCheck: "",
        width: 800,
        height: 500,
        url: "",
        checkRender: false,
        isPlay: true,
      });
      nextProp.setResetClick(false);
    }
    if (nextProp?.activeShow) {
      this.setState({ readyToReset: true });
    }
    if (this.state.readyToReset && !nextProp?.activeShow) {
      this.actionClick(this.state.itemActive, this.state.idActive);
      this.setState({ readyToReset: false });
    }
  }

  actionClick = async (value, id) => {
    this.setState({
      idActive: id,
      itemActive: value,
    });
    if (value.image_location.indexOf("MP4") > -1 || value.image_location.indexOf("mp4") > -1) {
      await this.setState({
        Videofile: true,
        imageCheck: "",
        url: value.image_location,
      });
    } else {
      await this.setState({
        Videofile: false,
        checkRender: false,
      });
      if (!this.state.Videofile) {
        await this.props.setURLImage(value.image_location);
        console.log(value.sub_images, "sub_images");
        await this.props.setImageSub(value.sub_images);
        this.setState({
          checkRender: true,
        });
      }
    }
  };

  removeItem = async (item, index) => {
    // TODO CUONG
    const patient_id = getLocal("current_patient_id");
    if (!item?.id || !patient_id) {
      return;
    }
    this.props.setGlobalLoading(true);
    const deleteSuccess = await delete_patient_image(item?.id);
    if (deleteSuccess) {
      await this.props.get_image_list(patient_id);
      (this.state.images || []).splice(index, 1);
      this.setState({
        imageCheck: "",
        url: "",
        idActive: -1,
        checkRender: false,
        images: [...(this.state.images || [])],
      });
      this.props.setGlobalToastr({
        header: "Delete image",
        message: "Delete image successfully!",
        type: "success",
      });
      this.setState({
        imageAdds: [],
        isCreate: false,
      });
    } else {
      this.props.setGlobalToastr({
        header: "Delete image",
        message: "Delete image failed!",
        type: "failed",
      });
    }
    this.props.setGlobalLoading(false);
  };

  render() {
    const { Hidelink, checkRender } = this.state;
    const { Videofile, url, idActive, indexActive } = this.state;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        {!Videofile ? (
          <div
            style={{
              backgroundColor: "black",
              height: "calc(100% - 85px)",
              width: "100%",
            }}
          >
            {checkRender ? <Dicom widthMax={"100%"} /> : <div className="size-dicom-default-view"></div>}
          </div>
        ) : (
          <div className="player-wrapper video-size-dicom">
            <ReactPlayer
              ref="videos"
              className="react-player"
              url={url}
              width="100%"
              height="100%"
              playing={this.state.isPlay}
              muted={true}
              volume={0}
              loop
              playsinline={true}
              autoPlay
            />
            <div className="player-control">
              <div className="player-control-container">
                <div
                  className="button-video-back"
                  onClick={() => {
                    this.setState({
                      isPlay: false,
                    });
                    this.refs.videos.seekTo(this.refs.videos.getCurrentTime() - 1);
                  }}
                >
                  <i className="play-backwards"></i>
                </div>
                <div
                  className="button-video-play-pause"
                  onClick={() => {
                    this.setState({
                      isPlay: !this.state.isPlay,
                    });
                  }}
                >
                  {this.state.isPlay ? <i className="play-pause"></i> : <i className="play-button"></i>}
                </div>
                <div
                  className="button-forward"
                  onClick={() => {
                    this.setState({
                      isPlay: false,
                    });
                    this.refs.videos.seekTo(this.refs.videos.getCurrentTime() + 1);
                  }}
                >
                  <i className="play-forwards"></i>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="tabHeaders" style={{ height: "10px" }}>
          {!Hidelink ? (
            <div
              style={{
                height: 80,
                marginTop: 5,
              }}
              className="reponsive-image-chart-dcom"
            >
              <Tabs
                TabIndicatorProps={{ style: { display: "none", backgroundColor: "red" } }}
                scrollButtons="auto"
                variant="scrollable"
                classes="test-ad"
                centered={true}
              >
                {(this.state.images || []).map((item, index) => (
                  <div
                    key={`tabHeaders-${item.id}`}
                    className={`tabHeaders-item ${idActive === item?.id ? "active" : ""} image-dicom`}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.actionClick(item, item?.id);
                      this.props.activeShowValue(false);
                    }}
                    style={{
                      backgroundImage: "url(" + item.thumbnail_url + ")",
                      marginLeft: index > 0 ? 5 : 0,
                      position: "relative",
                      backgroundSize: "contain",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "0px",
                        height: "30px",
                        width: "30px",
                        borderRadius: "100px",
                        background: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteIconButton
                        handleClick={(e) => {
                          e.preventDefault();
                          this.props.activeShowValue(false);
                          e.stopPropagation();
                          if (indexActive === index) {
                            this.setState({
                              indexActive: -1,
                            });
                            this.removeItem(item, index);
                          } else {
                            this.setState({
                              indexActive: index,
                            });
                          }
                        }}
                        selected={indexActive === index}
                        icon={"can"}
                        extraStyle={{
                          display: "inline-block",
                          right: "0.75rem",
                          marginRight: 20,
                          cursor: "pointer",
                          marginRight: 0,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </Tabs>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  image_list: state.common.image_list,
  id_image: state.common?.id_image,
  activeShow: state.dicom?.activeShow,
});

const mapDispatchToProps = {
  setURLImage,
  setImageSub,
  setGlobalLoading,
  setGlobalToastr,
  get_image_list,
  activeShowValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoImageViewer);
