import { Collapse, Drawer, Icon } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ReplaceNN from "../../../../helpers/ReplaceNN";
import HPISnapList from "./HPISnapList";
import EditableMaterialText from "src/common/components/EditableText/MaterialInput";
import SidebarTitle from "src/common/components/SidebarTitle";
import { getLocal } from "../../../../helpers/Local";
import {
  loadUltrasoundHistoryForConditionHPI,
  setGlobalLoading,
  setReminderData,
  updateReasonVisit,
  updateSameHpiSelectionReason,
} from "../../../../store/actions";

const HPISideBar = React.forwardRef(({ isShow, handleCloseSidebar, conditions, clickedCondition, sidebarIcon }, ref) => {
  const [ultrasoundList, setUltrasoundList] = useState([]);
  const [procedureList, setProcedureList] = useState([]);
  const [reason, setReason] = useState("");
  const [sameHpiSelectionReason, setSameHpiSelectionReason] = useState("");
  const [enableError, setEnableError] = useState(false);
  const [hasSameHpiLocal, setHasSameHpiLocal] = useState(false);
  const descList = useSelector((state) => state.clinicProviderHPI.descList);
  const globalLoading = useSelector((state) => state.common.globalLoading);
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const reason_for_visit = useSelector((state) => state.clinicProviderHPI.reason_for_visit);
  const same_hpi_selection_reason = useSelector((state) => state.clinicProviderHPI.same_hpi_selection_reason);
  const has_same_hpi = useSelector((state) => state.clinicProviderHPI.has_same_hpi);
  const ultrasounds = useSelector((state) => state.clinicProviderHPI.ultrasounds);
  const obl_hospital_appointments = useSelector((state) => state.clinicProviderHPI.obl_hospital_appointments);
  const current_appointment_id = getLocal("current_appointment_id");
  const dispatch = useDispatch();

  useEffect(() => {
    if (ultrasounds && ultrasounds.length > 0) {
      setUltrasoundList(ultrasounds);
    }
  }, [ultrasounds]);

  useEffect(() => {
    if (obl_hospital_appointments && obl_hospital_appointments.length > 0) {
      setProcedureList(obl_hospital_appointments);
    }
  }, [obl_hospital_appointments]);

  useEffect(() => {
    if (reason_for_visit) {
      setReason(reason_for_visit);
    }
  }, [reason_for_visit]);

  useEffect(() => {
    if (same_hpi_selection_reason) {
      setSameHpiSelectionReason(same_hpi_selection_reason);
    }
  }, [same_hpi_selection_reason]);

  useEffect(() => {
    setHasSameHpiLocal(has_same_hpi);
  }, [has_same_hpi]);

  useEffect(() => {
    if (!reason || reason.length == 0) {
      setEnableError(true);
    } else {
      setEnableError(false);
    }
  }, [reason]);

  const fullName = (patientResource?.first_name + " " + patientResource?.last_name).toUpperCase();
  const { deceased, cancelled_reason } = patientResource;
  const redReasons = ["Deceased", "Death", "Changed Provider"];

  const handleReasonChange = async (value) => {
    setReason(value);
    await dispatch(updateReasonVisit(current_appointment_id, { reason_for_visit: value }));
  };

  const handleSameHpiSelectionReasonChange = async (value) => {
    setSameHpiSelectionReason(value);
    await dispatch(updateSameHpiSelectionReason(current_appointment_id, { same_hpi_selection_reason: value }));
  };

  const reasonArr = [
    {
      id: 1,
      value: "New Patient Visit",
    },
    {
      id: 2,
      value: "Post-Procedure",
    },
    {
      id: 3,
      value: "Routine Scheduled Followup",
    },
    {
      id: 4,
      value: "Existing Patient with Worsening Disease Condition",
    },
    {
      id: 5,
      value: "Existing Patient with New Disease Condition",
    },
    {
      id: 6,
      value: "HBO Visit",
    },
  ];

  const reasonSameHpiArr = [
    {
      id: 1,
      value: "No Intervention",
    },
    {
      id: 2,
      value: "Staged Intervention",
    },
    {
      id: 3,
      value: "Recurrent Symptoms",
    },
  ];

  const renderFixStrong = (data) => {
    const str = (data || "").replace(/!!strong!!(.*?)!!strong!!/g, function (match, value) {
      if (value) {
        return `<b>${value}</b>`;
      }
      return value;
    });
    return str;
  };

  return (
    <Drawer
      title={`Condition Details - ${fullName} ${getLocal("current_appointment_id") ? `[${getLocal("current_appointment_id")}]` : ""}`}
      placement="right"
      variable="permanent"
      className={`${sideNavStatus ? "wide" : ""} ${deceased == "Yes" || (redReasons.includes(cancelled_reason) && cancelled_reason) ? "red" : ""}`}
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper>
          <div className="resourceInfo d-flex" style={{ marginTop: "1rem" }}>
            <EditableMaterialText
              label={"Reason for Visit"}
              isErrorTextSpacing={true}
              value={reason}
              handleChange={(value) => {
                handleReasonChange(value);
              }}
              type={"check"}
              optionKey={"value"}
              valueKey={"label"}
              options={reasonArr || []}
              isError={reason ? false : true}
              required={true}
              enableErrorText={enableError}
              helperText={"Reason for Visit is Required"}
            />
          </div>
          {(hasSameHpiLocal === 1 || sameHpiSelectionReason != "") && (
            <div className="resourceInfo d-flex" style={{ marginTop: "1rem" }}>
              <EditableMaterialText
                label={"Same HPI Selection Reason"}
                value={sameHpiSelectionReason}
                handleChange={(value) => {
                  handleSameHpiSelectionReasonChange(value);
                }}
                type={"check"}
                optionKey={"value"}
                valueKey={"label"}
                required={true}
                enableErrorText={true}
                options={reasonSameHpiArr || []}
                isError={sameHpiSelectionReason ? false : true}
                helperText={"Select reason for unchanged HPI following intervention"}
              />
            </div>
          )}
          <SidebarTitle
            icon={<Icon type="interaction" theme="twoTone" twoToneColor="#740937" />}
            title={<span className="text-uppercase">Ultrasound Reports</span>}
            subTitle=""
            style={{ marginTop: "2rem", marginBottom: "1rem" }}
          />
          <Collapse accordion style={{ marginLeft: "10px" }}>
            {(ultrasoundList || []).map((data, index) => (
              <Collapse.Panel
                header={
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ color: "#317CFE" }}>{`${data.date}`}</span>
                    <span style={{ color: "#595959", fontSize: "12.5px" }}>{`${data?.title}`}</span>
                  </div>
                }
                key={index}
              >
                <div style={{ fontWeight: "bold" }} className="snapDetail__content-title">
                  Finding:{" "}
                </div>
                {data?.finding && (data?.finding.trim() == "" || data?.finding == " ") ? (
                  <></>
                ) : (
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: renderFixStrong(ReplaceNN(data?.finding)),
                    }}
                  ></div>
                )}

                <div style={{ fontWeight: "bold", marginTop: 20 }} className="snapDetail__content-title">
                  Conclusion:{" "}
                </div>
                {data?.conclusion && (data?.conclusion.trim() == "" || data?.conclusion == " ") ? (
                  <></>
                ) : (
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: renderFixStrong(ReplaceNN(data?.conclusion)),
                    }}
                  ></div>
                )}
              </Collapse.Panel>
            ))}
          </Collapse>

          <SidebarTitle
            icon={<Icon type="interaction" theme="twoTone" twoToneColor="#740937" />}
            title={<span className="text-uppercase">Procedure Reports</span>}
            subTitle=""
            style={{ marginTop: "2rem", marginBottom: "1rem" }}
          />

          <Collapse accordion style={{ marginLeft: "10px" }}>
            {(procedureList || []).map((data, index) => (
              <Collapse.Panel
                header={
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ color: "#317CFE" }}>{`${data.date}`}</span>
                    <span style={{ color: "#595959", fontSize: "12.5px" }}>{`${data?.title}`}</span>
                  </div>
                }
                key={index}
              >
                <div style={{ fontWeight: "bold" }} className="snapDetail__content-title">
                  CPT Codes:{" "}
                </div>
                {data?.cpt && (data?.cpt.trim() == "" || data?.cpt == " ") ? (
                  <></>
                ) : (
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: ReplaceNN(data?.cpt),
                    }}
                  ></div>
                )}

                <div style={{ fontWeight: "bold" }} className="snapDetail__content-title">
                  Conclusion:{" "}
                </div>
                {data?.conclusion && (data?.conclusion.trim() == "" || data?.conclusion == " ") ? (
                  <></>
                ) : (
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: ReplaceNN(data?.conclusion),
                    }}
                  ></div>
                )}
              </Collapse.Panel>
            ))}
          </Collapse>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default HPISideBar;
